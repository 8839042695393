import InfoPage from 'components/info-page'
import ROUTES from 'helpers/routes'

function FourOhFour() {
  return (
    <InfoPage
      title="404"
      body="Sorry, we can't  seem to find the page you're looking for."
      ctaText=""
      ctaBtn={{
        link: ROUTES.MENU,
        target: '',
        text: 'Browse Menu'
      }}
    />
  )
}

export default FourOhFour
